import { createSlice } from '@reduxjs/toolkit';
import { MenuProps } from './types';

export const initialState: MenuProps = {
    selectedItem: [''],
    selectedID: null,
    drawerOpen: false,
    error: null,
    search: '',
    selectedModules: [] as string[],
    globalSearchList: [],
    notificationsList: [],
    isLoading: false,
    status: ''
};

const sidepanel = createSlice({
    name: 'sidepanelState',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        activeID(state, action) {
            state.selectedID = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setGlobalSearchList(state, action) {
            state.globalSearchList = action.payload;
        },
        setNotificationsList(state, action) {
            state.notificationsList = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },

        // has error
        hasError(state, action) {
            state.error = action.payload;
        },
        setGlobalSearch(state, action) {
            state.search = action.payload;
        },
        setSelectedModules(state, action) {
            state.selectedModules = action.payload;
        }
    }
});

export const { actions: SidepanelReducerAction, name: SidepanelReducerName, reducer: SidepanelReducer } = sidepanel;
