import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga } from 'store/saga-helper';
import { AgenciesReducerAction } from './slice';
import {
    CreateAgency,
    DeleteAgencyById,
    DownloadAgenciesData,
    DownloadFinancialsData,
    GetAgenciesData,
    GetAgencyDetailsById,
    GetAgentsByAgencyId,
    GetAllAgenciesList,
    GetFinancialsById,
    GetPropertiesByAgencyId,
    UpdateAgency,
    UpdateAgencyPassword
} from 'services/agencies';
import { SnackbarReducerAction } from 'store/snackbar/slice';
import { ConvertToQueryString, DownloadFile } from 'utils/common';
import routeConstant from 'routes/routeConstant';

const saga = createSliceSaga({
    name: 'agencies-state',
    caseSagas: {
        *fetchAllAgenciesList() {
            try {
                yield put(AgenciesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetAllAgenciesList);
                if (response?.status === 200) {
                    AgenciesReducerAction.setAgenciesList([]);
                    AgenciesReducerAction.setTotal(0);
                    yield put(AgenciesReducerAction.setAgenciesList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Agencies list API', error);
            } finally {
                yield put(AgenciesReducerAction.setIsLoading(false));
            }
        },
        *fetchAgenciesList(action: { payload: { [key: string]: any } }) {
            try {
                const { exportType } = action.payload;
                if (Boolean(exportType)) {
                    try {
                        const response: { [key: string]: any } = yield call(DownloadAgenciesData, ConvertToQueryString(action.payload));
                        DownloadFile({ response, fileName: 'AgenciesList', fileType: 'xlsx' });
                    } catch (error) {
                        console.log('Error in download data API', error);
                        throw new Error();
                    }
                } else {
                    yield put(AgenciesReducerAction.setIsLoading(true));
                    const response: AxiosResponse = yield call(GetAgenciesData, ConvertToQueryString(action.payload));
                    if (response?.status === 200) {
                        AgenciesReducerAction.setAgenciesList([]);
                        AgenciesReducerAction.setTotal(0);
                        yield put(AgenciesReducerAction.setAgenciesList(response?.data?.data));
                        yield put(AgenciesReducerAction.setTotal(response?.data?.totalCount));
                    }
                }
            } catch (error) {
                console.log('Error in Agencies list API', error);
            } finally {
                yield put(AgenciesReducerAction.setIsLoading(false));
            }
        },
        *getAgencyDetailsById(action: { payload: string }) {
            try {
                yield put(AgenciesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetAgencyDetailsById, String(action.payload));
                if (response?.status === 200) {
                    AgenciesReducerAction.setSelectedAgency({});
                    yield put(AgenciesReducerAction.setSelectedAgency(response?.data?.data[0]));
                }
            } catch (error) {
                console.log('Error in Get Agency by Id API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setIsLoading(false));
            }
        },
        *createAgency(action: { payload: { values: { [key: string]: any }; handleNavigate: (route: string) => void } }) {
            try {
                const { values, handleNavigate } = action.payload;
                yield put(AgenciesReducerAction.setAgencyFormIsLoading(true));
                const response: AxiosResponse = yield call(CreateAgency, values);
                if (response?.status === 200 || response?.status === 201) {
                    AgenciesReducerAction.setSelectedAgency({});
                    yield put(AgenciesSagaAction.fetchAllAgenciesList());
                    yield put(AgenciesSagaAction.getAgencyDetailsById(response?.data?.data?._id));
                    yield put(AgenciesReducerAction.setAgencySettingTab(1));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Agency Created Successfully'
                        })
                    );
                    handleNavigate(`${routeConstant.agenciesEdit}/${response?.data?.data?._id}`);
                } else {
                    yield put(
                        response?.data?.message &&
                            SnackbarReducerAction.openSnackbar({
                                open: true,
                                message: response?.data?.message
                            })
                    );
                }
            } catch (error) {
                console.log('Error in save Agency API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setAgencyFormIsLoading(false));
            }
        },
        *updateAgency(action: { payload: { [key: string]: any } }) {
            try {
                yield put(AgenciesReducerAction.setAgencyFormIsLoading(true));
                const response: AxiosResponse = yield call(UpdateAgency, action.payload);
                if (response?.status === 200) {
                    yield put(AgenciesSagaAction.fetchAllAgenciesList());
                    yield put(AgenciesSagaAction.getAgencyDetailsById(action.payload._id));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Agency details Updated Successfully'
                        })
                    );
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message ?? 'Error in Agency details Updated API'
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Update Agency API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setAgencyFormIsLoading(false));
            }
        },
        *deleteAgencyById(action: { payload: string }) {
            try {
                yield put(AgenciesReducerAction.setAgencyFormIsLoading(true));
                const response: AxiosResponse = yield call(DeleteAgencyById, action.payload);
                if (response?.status === 200) {
                    AgenciesReducerAction.setSelectedAgency([]);
                    yield put(AgenciesSagaAction.fetchAgenciesList({ page: 1, pageSize: 12 }));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Agency Deleted Successfully'
                        })
                    );
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message ?? 'Error in Delete Agency API'
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Delete Agency API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setAgencyFormIsLoading(false));
            }
        },
        *fetchAllPropertiesByAgencyId() {
            try {
                yield put(AgenciesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetPropertiesByAgencyId);
                if (response?.status === 200) {
                    AgenciesReducerAction.setPropertiesList([]);
                    yield put(AgenciesReducerAction.setPropertiesList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Agencies list API', error);
            } finally {
                yield put(AgenciesReducerAction.setIsLoading(false));
            }
        },
        *updateAgencyPassword(action: { payload: { [key: string]: any } }) {
            try {
                const { values, handleNavigate } = action.payload;
                yield put(AgenciesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(UpdateAgencyPassword, values);
                if (response?.status === 200) {
                    yield put(AgenciesSagaAction.fetchAllAgenciesList());
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Agency Password Updated Successfully'
                        })
                    );
                    handleNavigate();
                }
            } catch (error) {
                console.log('Error in Update Agency Password API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setIsLoading(false));
            }
        },
        *getAgentsByAgencyId(action: { payload: string }) {
            try {
                yield put(AgenciesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetAgentsByAgencyId, ConvertToQueryString({ agency_id: action.payload }));
                if (response?.status === 200) {
                    AgenciesReducerAction.setAgentsList({});
                    yield put(AgenciesReducerAction.setAgentsList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Get Agents by Agency Id API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setIsLoading(false));
            }
        },
        *getFinancialsById(action: { payload: { [key: string]: any } }) {
            try {
                const { exportType, agent_id, agency_id } = action.payload;
                if (Boolean(exportType)) {
                    try {
                        const response: { [key: string]: any } = yield call(
                            DownloadFinancialsData,
                            ConvertToQueryString({ agent_id, agency_id, exportType })
                        );
                        DownloadFile({ response, fileName: agency_id ? 'AgencyPropertiesList' : 'AgentPropertiesList', fileType: 'xlsx' });
                    } catch (error) {
                        console.log('Error in download data API', error);
                        throw new Error();
                    }
                } else {
                    yield put(AgenciesReducerAction.setFinancialsListLoading(true));
                    const response: AxiosResponse = yield call(GetFinancialsById, ConvertToQueryString(action.payload));
                    if (response?.status === 200) {
                        AgenciesReducerAction.setFinancialsListById({});
                        AgenciesReducerAction.setFinTotal(0);
                        yield put(AgenciesReducerAction.setFinancialsListById(response?.data));
                        yield put(AgenciesReducerAction.setFinTotal(response?.data?.totalCount));
                    }
                }
            } catch (error) {
                console.log('Error in Get financials by Id API', error);
                throw new Error();
            } finally {
                yield put(AgenciesReducerAction.setFinancialsListLoading(false));
            }
        }
    }
});

export const { actions: AgenciesSagaAction, name: AgenciesSagaName, saga: AgenciesSaga } = saga;
