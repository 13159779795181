import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.sidepanelState || initialState;

export const sidepanelStateSelector = createSelector(selectDomain, (state) => state);
export const isLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const selectedModulesSelector = createSelector(selectDomain, (state) => state.selectedModules);
export const globalSearchListSelector = createSelector(selectDomain, (state) => state.globalSearchList);
export const notificationsListSelector = createSelector(selectDomain, (state) => state.notificationsList);
export const statusSelector = createSelector(selectDomain, (state) => state.status);
