import { call, put, select } from 'redux-saga/effects';
import { createSliceSaga } from 'store/saga-helper';
import { PropertiesReducerAction } from './slice';
import { AxiosResponse } from 'axios';
import { DeletePropertyById, DownloadPropertiesList, GetPropertiesData, GetPropertiesDetailsById } from 'services/property/propertyListing';
import {
    AddNewPropertyUnit,
    AddUnitModification,
    CreateCalculationsForUnit,
    DeleteUnitModification,
    DownloadPropertyClientsDataById,
    DownloadPropertyUnitsDataById,
    EditPropertyUnit,
    EditUnitModification,
    EditUnitsFinancials,
    GetClientsAutocomplete,
    GetFinancialsByUnitId,
    GetPropertyClientsDetailsById,
    GetPropertyDocumentsById,
    GetPropertyGalleryDataById,
    GetPropertyUnitById,
    GetPropertyUnitsDetailsById,
    GetPropertyUpdatesDetailsById,
    GetSuppliersListByType,
    GetUnitsFinancialsById,
    GetUnitsModificationsById
} from 'services/property/propertiesDetails';
import { GalleryImagesType, PropertyGalleryListDataType } from './types';
import {
    AddEditGalleryImages,
    AddPropertyDetails,
    DeletePropertyUnit,
    GetPropertyEditUnitsDetailsById,
    GetPropertyGallery,
    UpdatePropertyDataById
} from 'services/property/propertyEdit';
import { SnackbarReducerAction } from 'store/snackbar/slice';
import { ClientsReducerAction } from 'store/clients/slice';
import { ImageRequestPayloadType, multipleImageUpload } from 'services/imageUpload/imageUploadServices';
import { propertyDetailGalleryImagesSelector } from './selector';
import { ConvertToQueryString, DownloadFile } from 'utils/common';
import { UpdatesReducerAction } from 'store/updates/slice';
import routeConstant from 'routes/routeConstant';

const saga = createSliceSaga({
    name: 'properties-state',
    caseSagas: {
        *fetchPropertyList(action: { payload: { [key: string]: any } }) {
            try {
                const { exportType } = action.payload;
                if (Boolean(exportType)) {
                    yield put(PropertiesReducerAction.setIsLoading(true));
                    try {
                        const response: { [key: string]: any } = yield call(DownloadPropertiesList, ConvertToQueryString(action.payload));
                        DownloadFile({ response, fileName: 'PropertiesList', fileType: 'xlsx' });
                    } catch (error) {
                        console.log('Error in download data API', error);
                        throw new Error();
                    }
                } else {
                    yield put(PropertiesReducerAction.setIsLoading(true));
                    const response: AxiosResponse = yield call(GetPropertiesData, ConvertToQueryString(action.payload));
                    if (response?.status === 200) {
                        PropertiesReducerAction.setPropertiesDetails([]);
                        PropertiesReducerAction.setTotal(0);
                        yield put(PropertiesReducerAction.setPropertiesDetails(response?.data));
                        yield put(PropertiesReducerAction.setTotal(response?.data?.totalCount));
                    }
                }
            } catch (error) {
                console.log('Error in Properties API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *fetchPropertiesDetailsById(action: {
            payload: {
                id: string;
            };
            type: string;
        }) {
            try {
                yield put(PropertiesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetPropertiesDetailsById, String(action.payload));
                if (response?.status === 200) {
                    PropertiesReducerAction.setPropertiesDetails([]);
                    yield put(PropertiesReducerAction.setPropertiesDetails(response?.data));
                }
            } catch (error) {
                console.log('Error in Properties API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *deletePropertyDataById(action: { payload: { id: string; handleNavigate: () => void } }) {
            try {
                const { id, handleNavigate } = action.payload;
                yield put(PropertiesReducerAction.setToggleDeleteModal(false));
                yield put(PropertiesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(DeletePropertyById, String(id));
                if (response?.status === 200) {
                    yield put(PropertiesSagaActions.fetchPropertyList({ page: 1, pageSize: 12 }));
                    yield put(PropertiesReducerAction.setSelectedProperty({}));
                    handleNavigate();
                }
            } catch (error) {
                yield put(PropertiesReducerAction.setIsLoading(false));
                console.log('Error in Properties API', error);
                throw new Error();
            }
        },
        *fetchPropertyUnitsList(action: {
            payload: {
                id: string;
                page: number;
                pageSize: number;
                sortBy: string;
                sortOrder: string;
                exportType?: string;
            };
            type: string;
        }) {
            try {
                const { id, exportType } = action.payload;
                if (Boolean(exportType)) {
                    try {
                        const response: { [key: string]: any } = yield call(DownloadPropertyUnitsDataById, {
                            id,
                            query: ConvertToQueryString(action.payload)
                        });
                        DownloadFile({ response, fileName: 'PropertyUnitsList', fileType: 'xlsx' });
                    } catch (error) {
                        console.log('Error in download data API', error);
                        throw new Error();
                    }
                } else {
                    yield put(PropertiesReducerAction.setPropertyUnitsListLoading(true));
                    const response: AxiosResponse = yield call(GetPropertyUnitsDetailsById, {
                        id: id,
                        query: ConvertToQueryString(action.payload)
                    });
                    if (response?.status === 200) {
                        PropertiesReducerAction.setPropertyUnitsDetails([]);
                        PropertiesReducerAction.setUnitsTotal(0);
                        yield put(PropertiesReducerAction.setUnitsPage(response?.data?.currentPage));
                        yield put(PropertiesReducerAction.setPropertyUnitsDetails(response?.data));
                        yield put(PropertiesReducerAction.setUnitsTotal(response?.data?.totalCount));
                    }
                }
            } catch (error) {
                console.log('Error in Properties Units API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setPropertyUnitsListLoading(false));
            }
        },
        *fetchPropertyClientsList(action: {
            payload: {
                userId: string;
                page: number;
                pageSize: number;
                sortBy: string;
                exportType?: string;
            };
            type: string;
        }) {
            try {
                const { userId, exportType } = action.payload;
                if (Boolean(exportType)) {
                    try {
                        const response: { [key: string]: any } = yield call(DownloadPropertyClientsDataById, {
                            userId,
                            query: ConvertToQueryString(action.payload)
                        });
                        DownloadFile({ response, fileName: 'PropertyClientsList', fileType: 'xlsx' });
                    } catch (error) {
                        console.log('Error in download data API', error);
                        throw new Error();
                    }
                } else {
                    yield put(ClientsReducerAction.setIsLoading(true));
                    const response: AxiosResponse = yield call(GetPropertyClientsDetailsById, {
                        userId: String(userId),
                        query: ConvertToQueryString(action.payload)
                    });
                    if (response?.status === 200) {
                        ClientsReducerAction.setClientsDetails([]);
                        ClientsReducerAction.setPage(0);
                        yield put(ClientsReducerAction.setClientsDetails(response?.data));
                        yield put(ClientsReducerAction.setPage(response?.data?.currentPage));
                        yield put(ClientsReducerAction.setTotal(response?.data?.totalCount));
                    }
                }
            } catch (error) {
                console.log('Error in Properties Clients API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        },
        *fetchPropertyUpdatesList(action: {
            payload: {
                userId: string;
                page: number;
                pageSize: number;
                sortBy: string;
            };
            type: string;
        }) {
            try {
                const { userId, page, pageSize, sortBy } = action.payload;
                yield put(PropertiesReducerAction.setIsPropertyUpdatesListLoading(true));
                const response: AxiosResponse = yield call(GetPropertyUpdatesDetailsById, {
                    userId: userId,
                    query: ConvertToQueryString({ page, pageSize, sortBy })
                });
                if (response?.status === 200) {
                    UpdatesReducerAction.setUpdatesListByUserId([]);
                    UpdatesReducerAction.setTotal(0);
                    yield put(UpdatesReducerAction.setUpdatesListByUserId(response?.data));
                    yield put(UpdatesReducerAction.setTotal(response?.data?.totalCount));
                }
            } catch (error) {
                console.log('Error in Properties Updates API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsPropertyUpdatesListLoading(false));
            }
        },
        *fetchPropertyDocumentsList(action: {
            payload: {
                userId: string;
            };
            type: string;
        }) {
            try {
                yield put(PropertiesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetPropertyDocumentsById, String(action.payload));
                if (response?.status === 200) {
                    PropertiesReducerAction.setPropertyDocumentsList([]);
                    yield put(PropertiesReducerAction.setPropertyDocumentsList(response?.data));
                }
            } catch (error) {
                console.log('Error in Properties Documents API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *fetchPropertyGalleryList(action: {
            payload: {
                userId: string;
            };
            type: string;
        }) {
            try {
                yield put(PropertiesReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetPropertyGalleryDataById, String(action.payload));
                if (response?.status === 200) {
                    PropertiesReducerAction.setPropertyGalleryList([]);
                    const galleryList = response?.data?.data.map((image: PropertyGalleryListDataType) => {
                        return {
                            src: image?.dropbox_url ?? '',
                            caption: image?.description ?? '',
                            alt: image?.name ?? ''
                        };
                    });

                    yield put(PropertiesReducerAction.setPropertyGalleryList(galleryList));
                }
            } catch (error) {
                console.log('Error in Properties Gallery API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *editPropertyById(action: { payload: { id: string; updatedData: any; handleNavigate: () => void }; type: string }) {
            try {
                const { id, updatedData, handleNavigate } = action.payload;
                yield put(PropertiesReducerAction.setIsAddPropertyLoading(true));
                const response: AxiosResponse = yield call(UpdatePropertyDataById, id, updatedData);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Property Updated Successfully'
                        })
                    );
                    handleNavigate();
                    // SnackbarReducerAction
                    // alert('Property Updated Successfully'); // TODO: Snackbar Integration
                }
            } catch (error) {
                console.log('Error in Properties Edit API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsAddPropertyLoading(false));
            }
        },
        *addProperty(action: { payload: { data: any; handleNavigate: () => void }; type: string }) {
            try {
                const { data, handleNavigate } = action.payload;
                yield put(PropertiesReducerAction.setIsAddPropertyLoading(true));
                const response: AxiosResponse = yield call(AddPropertyDetails, data);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Property created successfully'
                        })
                    );
                    handleNavigate();
                } else {
                    console.log(response, 'else');
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Properties Edit API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsAddPropertyLoading(false));
            }
        },
        *fetchPropertyEditUnitsList(action: { payload: { id: string }; type: string }) {
            yield put(PropertiesReducerAction.setIsLoading(true));
            try {
                const response: AxiosResponse = yield call(GetPropertyEditUnitsDetailsById, action.payload.id);
                if (response?.status === 200) {
                    PropertiesReducerAction.setPropertyEditUnitsDetails([]);
                    yield put(PropertiesReducerAction.setPropertyEditUnitsDetails(response?.data));
                }
            } catch (error) {
                console.log('Error in Properties Units API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *fetchPropertyDetails(action: { payload: string }) {
            yield put(PropertiesReducerAction.setIsLoading(true));
            try {
                const response: AxiosResponse = yield call(GetPropertiesDetailsById, String(action.payload));
                if (response?.status === 200) {
                    PropertiesReducerAction.setSelectedProperty([]);
                    yield put(PropertiesReducerAction.setSelectedProperty(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Properties Details API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *deletePropertyUnitById(action: { payload: { selectedUnit: string; propertyId: string } }) {
            yield put(PropertiesReducerAction.setIsLoading(true));
            try {
                yield put(PropertiesReducerAction.setToggleDeleteModal(false));
                const response: AxiosResponse = yield call(DeletePropertyUnit, String(action.payload.selectedUnit));
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Property Unit Deleted Successfully'
                        })
                    );
                    yield put(
                        PropertiesSagaActions.fetchPropertyUnitsList({ id: response?.data?.data?.properties_id, page: 1, pageSize: 12 })
                    );
                }
            } catch (error) {
                console.log('Error in Properties Details API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *getPropertyDetailGallery(action: { payload: { propertyId: string } }) {
            yield put(PropertiesReducerAction.setIsLoading(true));
            try {
                const response: AxiosResponse = yield call(GetPropertyGallery, action.payload.propertyId);
                if (response?.status === 200) {
                    yield put(PropertiesReducerAction.setPropertyDetailGalleryImages(response.data.data));
                }
            } catch (error) {
                console.log('Error in get properties Detail Gallery API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        // upload multiple images and get image URLs
        *uploadMultipleGalleryImage(action: { payload: { image: File[]; propertyId: string } }) {
            yield put(PropertiesReducerAction.setIsLoading(true));
            try {
                const propertyDetailGalleryImages: GalleryImagesType[] = yield select(propertyDetailGalleryImagesSelector);
                const response: AxiosResponse = yield call(multipleImageUpload, action.payload.image);

                if (response?.status === 200) {
                    if (response.data.imageUrls.length > 0) {
                        let galleryImages = response.data.imageUrls;
                        if (propertyDetailGalleryImages.length > 0)
                            galleryImages = [...propertyDetailGalleryImages, ...response.data.imageUrls];
                        const payload = {
                            images: galleryImages,
                            property_id: action.payload.propertyId
                        };
                        yield put(PropertiesSagaActions.setPropertyDetailGallery(payload));
                    }
                }
            } catch (error: any) {
                yield put(
                    SnackbarReducerAction.openSnackbar({
                        open: true,
                        message: error.error
                    })
                );
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        // send image URLs to backend and store
        *setPropertyDetailGallery(action: { payload: ImageRequestPayloadType }) {
            yield put(PropertiesReducerAction.setIsLoading(true));
            try {
                const response: AxiosResponse = yield call(AddEditGalleryImages, action.payload);
                if (response?.status === 200) {
                    yield put(PropertiesSagaActions.getPropertyDetailGallery({ propertyId: action.payload?.property_id }));
                }
            } catch (error) {
                console.log('Error in set properties Detail Gallery API', error);
            }
        },
        *fetchPropertyUnitById(action: { payload: string }) {
            try {
                const response: AxiosResponse = yield call(GetPropertyUnitById, action.payload);
                if (response?.status === 200) {
                    PropertiesReducerAction.setSelectedPropertyUnit({});
                    yield put(PropertiesReducerAction.setSelectedPropertyUnit(response?.data?.data));
                    yield put(PropertiesSagaActions.fetchPropertyDetails(response?.data?.data?.properties_id));
                }
            } catch (error) {
                console.log('Error in Properties Units API', error);
                throw new Error();
            }
        },
        *addPropertyUnit(action: { payload: { [key: string]: any } }) {
            try {
                const { data, handleNavigate } = action.payload;
                yield put(PropertiesReducerAction.setIsAddPropertyUnitLoading(true));
                const response: AxiosResponse = yield call(AddNewPropertyUnit, data);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Property's Unit Added Successfully"
                        })
                    );
                    handleNavigate(`${routeConstant.unitEdit}/${response?.data?.data?._id}`);
                }
            } catch (error) {
                console.log("Error in Propert's Unit add API", error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsAddPropertyUnitLoading(false));
            }
        },
        *editPropertyUnit(action: { payload: { [key: string]: any } }) {
            try {
                yield put(PropertiesReducerAction.setIsAddPropertyUnitLoading(true));
                const response: AxiosResponse = yield call(EditPropertyUnit, action.payload);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Property's Unit Updated Successfully"
                        })
                    );
                }
            } catch (error) {
                console.log("Error in Property's Unit Edit API", error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsAddPropertyUnitLoading(false));
            }
        },
        *fetchUnitsFinancialsById(action: { payload: string }) {
            try {
                const response: AxiosResponse = yield call(GetUnitsFinancialsById, action.payload);
                if (response?.status === 200) {
                    PropertiesReducerAction.setUnitsFinancialsList([]);
                    yield put(PropertiesReducerAction.setUnitsFinancialsList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Properties Units API', error);
                throw new Error();
            }
        },
        *editUnitsFinancialsUnit(action: { payload: { [key: string]: any } }) {
            try {
                const response: AxiosResponse = yield call(EditUnitsFinancials, action.payload);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Unit Financials Updated Successfully'
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Unit Financials Edit API', error);
                throw new Error();
            }
        },
        *fetchUnitModificationsById(action: { payload: string }) {
            try {
                const response: AxiosResponse = yield call(GetUnitsModificationsById, action.payload);
                if (response?.status === 200) {
                    PropertiesReducerAction.setUnitModificationsList([]);
                    yield put(PropertiesReducerAction.setUnitModificationsList(response?.data?.data));
                }
            } catch (error) {
                console.log("Error in fetch Property's Unit modifications API", error);
                throw new Error();
            }
        },
        *addPropertyUnitModification(action: { payload: { [key: string]: any } }) {
            try {
                const response: AxiosResponse = yield call(AddUnitModification, action.payload);
                if (response?.status === 200) {
                    yield put(PropertiesSagaActions.fetchUnitModificationsById(response?.data?.data?.unit_id));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Unit's modification Added Successfully"
                        })
                    );
                }
            } catch (error) {
                console.log("Error in Add unit's modification API", error);
                throw new Error();
            }
        },
        *editPropertyUnitModification(action: { payload: { [key: string]: any } }) {
            try {
                const response: AxiosResponse = yield call(EditUnitModification, action.payload);
                if (response?.status === 200) {
                    yield put(PropertiesSagaActions.fetchUnitModificationsById(response?.data?.data?.unit_id));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Unit's Modification Updated Successfully"
                        })
                    );
                }
            } catch (error) {
                console.log("Error in Edit Unit's Modification API", error);
                throw new Error();
            }
        },
        *deleteUnitModificationById(action: { payload: { unitId: string; modificationId: string } }) {
            try {
                yield put(PropertiesReducerAction.setToggleDeleteModal(false));
                const response: AxiosResponse = yield call(DeleteUnitModification, String(action.payload.modificationId));
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Unit's Modification Deleted Successfully"
                        })
                    );
                    yield put(PropertiesSagaActions.fetchUnitModificationsById(action.payload.unitId));
                }
            } catch (error) {
                console.log("Error in Delete Unit's modification API", error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsLoading(false));
            }
        },
        *fetchUnitFinancialsById(action: { payload: string }) {
            try {
                const response: AxiosResponse = yield call(GetFinancialsByUnitId, action.payload);
                if (response?.status === 200) {
                    PropertiesReducerAction.setUnitFinancialsList([]);
                    yield put(PropertiesReducerAction.setUnitFinancialsList(response?.data?.data));
                }
            } catch (error) {
                console.log("Error in fetch Property's Unit Financials API", error);
                throw new Error();
            }
        },
        *createCalculationsForUnit(action: { payload: { [key: string]: any } }) {
            try {
                yield put(PropertiesReducerAction.setIsAddPropertyUnitLoading(true));
                const response: AxiosResponse = yield call(CreateCalculationsForUnit, action.payload);
                if (response?.status === 200) {
                    yield put(PropertiesSagaActions.fetchUnitFinancialsById(response?.data?.data?.unit_id));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: "Unit's Financials Updated Successfully"
                        })
                    );
                }
            } catch (error) {
                console.log("Error in Add unit's Financials API", error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setIsAddPropertyUnitLoading(false));
            }
        },
        *fetchClientsAutoComplete(action: { payload: { [key: string]: any } }) {
            try {
                const response: AxiosResponse = yield call(GetClientsAutocomplete, ConvertToQueryString(action.payload));
                if (response?.status === 200) {
                    PropertiesReducerAction.setClientsList([]);
                    yield put(PropertiesReducerAction.setClientsList(response?.data?.data));
                }
            } catch (error) {
                console.log("Error in fetch Property's Unit Financials API", error);
                throw new Error();
            }
        },
        *fetchSuppliersListByType(action: { payload: { [key: string]: string } }) {
            try {
                const { supplier_type } = action.payload;
                const response: AxiosResponse = yield call(GetSuppliersListByType, ConvertToQueryString(action.payload));
                if (response?.status === 200) {
                    if (supplier_type === 'project_manager') {
                        PropertiesReducerAction.setTechniciansList([]);
                        yield put(PropertiesReducerAction.setTechniciansList(response?.data?.suppliers));
                    } else if (supplier_type === 'fiscal') {
                        PropertiesReducerAction.setFiscalRepresentativesList([]);
                        yield put(PropertiesReducerAction.setFiscalRepresentativesList(response?.data?.suppliers));
                    } else if (supplier_type === 'legal') {
                        PropertiesReducerAction.setLegalRepresentativesList([]);
                        yield put(PropertiesReducerAction.setLegalRepresentativesList(response?.data?.suppliers));
                    } else {
                        PropertiesReducerAction.setArchitectsList([]);
                        yield put(PropertiesReducerAction.setArchitectsList(response?.data?.suppliers));
                    }
                }
            } catch (error) {
                console.log('Error in fetch Suppliers List API', error);
                throw new Error();
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: PropertiesSagaActions, saga: PropertiesSaga, name: PropertiesSagaName } = saga;
