import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import color from 'assets/scss/_themes-vars.module.scss';
import {
    Avatar,
    Badge,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Skeleton,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanelSagaAction } from 'store/sidepanel/saga';
import { isLoadingSelector, notificationsListSelector, statusSelector } from 'store/sidepanel/selector';
import { SidepanelReducerAction } from 'store/sidepanel/slice';
import '../style.scss';

// notification status options
const statusList = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'New',
        label: 'New'
    },
    {
        value: 'Read',
        label: 'Read'
    },
    {
        value: 'Other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const notificationsList = useSelector(notificationsListSelector);
    const status = useSelector(statusSelector);
    const isLoading = useSelector(isLoadingSelector);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        dispatch(SidePanelSagaAction.fetchNotificationsData({ status: status.includes('all') ? '' : status }));
    }, [pathname, status]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && dispatch(SidepanelReducerAction.setStatus(event?.target?.value));
    };

    const handleMarkAllRead = () => {
        dispatch(SidePanelSagaAction.updateNotificationStatus());
    };
    return (
        <>
            <Box
                sx={{
                    ml: 5,
                    [theme.breakpoints.down('lg')]: {
                        mr: 2
                    }
                }}
            >
                <Badge
                    badgeContent={notificationsList?.filter((item: { [key: string]: any }) => item?.status === 'New')?.length}
                    color="primary"
                >
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: color,
                            color: theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper style={{ width: '370px' }}>
                                {open && (
                                    <MainCard
                                        border={false}
                                        elevation={16}
                                        content={false}
                                        boxShadow
                                        shadow={theme.shadows[16]}
                                        sx={{ padding: '20px' }}
                                    >
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="h4">All Notification</Typography>
                                                            <Chip
                                                                size="small"
                                                                label={notificationsList?.length}
                                                                sx={{
                                                                    color: color.primaryDark,
                                                                    bgcolor: color.primaryLight
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            component={Link}
                                                            to="#"
                                                            variant="h6"
                                                            color={color.primaryDark}
                                                            style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                            onClick={handleMarkAllRead}
                                                        >
                                                            Mark as all read
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ px: 2, pt: 0.25 }}>
                                                                <TextField
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    fullWidth
                                                                    value={status}
                                                                    onChange={handleChange}
                                                                    SelectProps={{
                                                                        native: true
                                                                    }}
                                                                >
                                                                    {statusList.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    {isLoading ? (
                                                        <Skeleton className="skeleton-loader" />
                                                    ) : notificationsList?.length ? (
                                                        <NotificationList
                                                            notificationsList={notificationsList}
                                                            handleClose={() => setOpen(false)}
                                                        />
                                                    ) : (
                                                        !isLoading && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                display={'flex'}
                                                                py={2}
                                                                justifyContent={'center'}
                                                                flexWrap={'wrap'}
                                                            >
                                                                No Notifications found!
                                                            </Grid>
                                                        )
                                                    )}
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
