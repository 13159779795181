import { useMemo } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './style.scss';
import { HomeIcon } from 'ui-component/Icons';
import color from 'assets/scss/_themes-vars.module.scss';
import useAuth from 'hooks/useAuth';

const BreadCrumb = ({
    module,
    customPathEndPointName,
    noPath = false,
    subModule,
    homeIcon,
    dynamicLink
}: {
    module: string;
    customPathEndPointName?: string;
    subTitle?: string;
    subModule?: string[];
    noPath?: boolean;
    homeIcon?: boolean;
    dynamicLink?: string;
}) => {
    const location = useLocation();
    const { loggedInUserType } = useAuth();
    const breadcrumbs = useMemo(() => {
        const pathName = location.pathname.slice(1);
        const pathEndPointName = pathName.charAt(0).toUpperCase() + pathName.slice(1);
        const link = dynamicLink ?? module.toLowerCase();
        let breadbrumbTemp = [
            <Link className="breadcrumb-content" underline="hover" href={'/'}>
                {HomeIcon}
                <Typography key="3" color={color.grey550} fontWeight="700" fontSize={'0.75rem'}>
                    {loggedInUserType?.toString()[0]}
                    {loggedInUserType?.toString().slice(1).toLowerCase()}
                </Typography>
            </Link>,
            <Link component={RouterLink} underline="hover" key="1" color="inherit" to={`/${link}`}>
                <Typography color={color.grey550} fontWeight="700" fontSize={'0.75rem'} key="3">
                    {module ? module : pathEndPointName}
                </Typography>
            </Link>,
            customPathEndPointName && (
                <Typography color={color.grey550} fontWeight="700" fontSize={'0.75rem'} key="3">
                    {customPathEndPointName ? customPathEndPointName : ''}
                </Typography>
            )
        ];
        if (Boolean(subModule)) {
            subModule?.map((module: string) =>
                breadbrumbTemp.push(
                    <Typography key="2" color={color.grey550} fontWeight="700" fontSize={'0.75rem'}>
                        {module}
                    </Typography>
                )
            );
        }
        return breadbrumbTemp;
    }, [subModule, customPathEndPointName, module, noPath, location.pathname]);

    return (
        <Stack className="breadcrumb-main" spacing={2} padding={2} paddingRight={'20px'}>
            <Breadcrumbs
                separator={noPath ? <></> : <NavigateNextIcon sx={{ height: '25px', width: '24px', color: color.grey550 }} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
};

export default BreadCrumb;
